<template>
    <modal ref="modalCreateBlock" titulo="Nuevo bloque" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="guardarEditar">
        <div class="row mx-2">
            <p class="f-16 col-12 text-general f-500 mb-3">Bloque {{ posicion+1 }}</p>
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-2 mb-3">
                <div class="col-6">
                    <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                        <p class="pl-2 f-12">Título</p>
                        <el-input v-model="footer.texto" placeholder="Nombre" class="w-100" size="small" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-2">
                <div class="col-6">
                    <p class="pl-2 f-12">Nueva sección</p>
                    <el-input v-model="title" placeholder="Nombre de la sección" class="w-100" size="small" />
                </div>
                <div class="col-5">
                    <p class="pl-2 f-12">URL</p>
                    <el-input v-model="url" placeholder="Añadir enlace" class="w-100" size="small" />
                </div>
                <div class="col- mt-auto">
                    <el-tooltip content="Crear sección" effect="light" :show-arrow="false" :offset="0">
                        <div class="wh-36px bg-general br-8 cr-pointer d-middle-center text-white" @click="addItem">
                            <i class="icon-plus" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <draggable :list="secciones" handle=".handle" class="" @change="changePositionSection">
                <div v-for="(data,idx) in secciones" :key="idx" class="row mx-2 my-2">
                    <div class="col-6 d-flex">
                        <i class="icon-drag f-22 cr-pointer handle text-muted" />
                        <ValidationProvider v-slot="{errors}" rules="required" name="texto">
                            <el-input v-model="data.texto" placeholder="Nombre de la sección" class="w-100" size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-5">
                        <el-input v-model="data.ruta" placeholder="Añadir enlace" class="w-100" size="small" />
                    </div>
                    <div class="col- my-auto">
                        <i class="icon-delete-trash f-20 ml-1 cr-pointer" @click="eliminarItem(idx)" />
                    </div>
                </div>
            </draggable>
        </ValidationObserver>
    </modal>
</template>

<script>
import Footer from "~/services/landing/landingFooter";
export default {
    data(){
        return {
            title:'',
            url:'',
            secciones: [],
            footer:{
                id: null,
                texto: ''
            },
            modoActualizar: false,
            posicion: 0,
        }
    },
    methods: {
        toggle(bandera, footer, index){
            this.clear();
            if(bandera === 'editar'){
                this.modoActualizar = true;
                this.secciones = footer.items;
                this.footer.texto = footer.texto;
                this.footer.id = footer.id;
                this.posicion = index;
            }
            this.$refs.modalCreateBlock.toggle();
        },
        addItem(){
            this.secciones.push({
                texto: this.title,
                ruta: this.url
            });

            this.title = '';
            this.url = '';
        },
        changePositionSection(){
        },
        guardarEditar(){
            if (this.modoActualizar){
                this.editarBloqueFooters();
            }else{
                this.guardarFooter();
            }
        },
        async guardarFooter(){
            try {
                const valid = await this.$refs.validacion.validate()
                if (!valid || this.footer.texto == ''){
                    this.notificacion('Advertencia','Por favor ingrese un nombre al bloque','warning')
                    return;
                }

                const payload = {
                    texto: this.footer.texto,
                    items: this.secciones,
                }

                await Footer.guardarBloqueFooters(payload);
                
                this.clear();
                this.notificacion('Mensaje', 'Guardado exitoso', 'success');                
                this.$refs.modalCreateBlock.toggle();
                this.$emit('update');           
            } catch(e){
                this.error_catch(e)
            }
        },
        async editarBloqueFooters(){
            try {
                const valid = await this.$refs.validacion.validate()
                if (!valid || this.footer.texto == ''){
                    this.notificacion('Advertencia','Por favor ingrese un nombre al bloque','warning')
                    return;
                }

                const payload = {
                    idFooter: this.footer.id,
                    texto: this.footer.texto,
                    items: this.secciones,
                }

                await Footer.editarBloqueFooters(payload);
                
                this.clear();
                this.notificacion('Mensaje', 'Actualizado exitoso', 'success');                
                this.$refs.modalCreateBlock.toggle();  
                this.$emit('update');            
            } catch(e){
                this.error_catch(e)
            }
        },
        eliminarItem(item){
            this.secciones.splice(item, 1);
        },
        clear(){
            this.posicion = 0;
            this.modoActualizar = false;
            this.title = '';
            this.url = '';
            this.secciones = [];
            this.footer = {
                id: null,
                texto: ''
            };
        }

    }
}
</script>
<style lang="scss" scoped>
.wh-36px{
	max-width: 36px;
	min-width: 36px;
	min-height: 36px;
	max-height: 36px;
}
</style>
